@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Poppins", sans-serif;
}


@font-face {
  font-family: Poppins;
  src: url("../public/font/Poppins/Poppins-Medium.ttf");
  font-display: swap;
}
.font-Playfair{
  font-family: 'Playfair Display', serif;
}
@font-face {
  font-family: Inter;
  src: url("../public/font/Inter/Inter-SemiBold.ttf");
  font-display: swap;
}
.font-oswald{
  font-family: 'Oswald', sans-serif;
}
.font-nunito{
  font-family: 'Nunito', sans-serif;
}

.form-input-color input,textarea{
  background-color: transparent !important;
  /* color: #fff; */
}
.tableRowLink {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.message {
  display: flex;
  flex-direction: column;
  max-width: 70%;
  margin-bottom: 8px;
}

.user {
  align-self: flex-end;
  background-color: lightblue;
  color: white;
  border-radius: 8px 8px 0 8px;
}

.server {
  align-self: flex-start;
  background-color: white;
  color: black;
  
}
.no-padding .parent-pad{
  padding: 16px !important;
}
.header-menu li {
  position: relative;
}
.leading-10 {
  line-height: 2.5rem !important;
}
.slick-track {
  margin: 0 -10px !important;
  display: flex !important;
  align-items: stretch;
}
.card-slider .slick-slide {
  padding-top: 55px !important;
}
.slick-slide > div {
  margin: 0 10px;
  height: 100%;
}
.slick-list {
  margin: 0 -10px;
}
.slick-initialized .slick-slide {
  white-space: pre-wrap;
  height: auto !important;
}

.header-menu a.active::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
  background: #39fd13;
  height: 6px;
  width: 130%;
}

.header-menu button.active::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
  background: #39fd13;
  height: 6px;
  width: 130%;
}

.after-line::after {
  content: "";
  position: absolute;
  width: 105px;
  height: 2px;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
  bottom: -8px;
  background: #39fd13;
}

.otp-inputs input {
  background: #f1f1f1;
  color: #837575;
  border: 1px solid #7e7777;
  border-radius: 0.5rem;
  width: 4rem !important;
  height: 3rem !important;
  /* padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; */
}
.otp-inputs div:only-child {
  align-items: center;
  width: 75%;
  align-items: center;
  justify-content: space-around;
}
.otp-inputs input:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.circle-shadow::after {
  content: "";
  box-shadow: -13px -12px 0px -11px rgb(58 253 19);
}

.slick-prev, .slick-next{
  margin-top: 30px;
}
.slick-list{
  /* padding:0 20% 0 0 !important; */
  /* overflow: visible !important; */
}
.react-ios-time-picker {
  margin-bottom: 16%!important;
  position: absolute;
  /* bottom: 0px; */
  /* left: 0px; */
  right: 29%;
  overflow: hidden;
  box-shadow: 0 11px 15px #0005;
}

/* scroll bar css for table */

#style-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0;
  background-color: #f5f5f5;
}

#style-1::-webkit-scrollbar {
  width: 5px;
  background-color: #fff;
}

#style-1::-webkit-scrollbar-thumb {
  border-radius: 0;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

/*clippath*/
/* .clippath {
  clip-path: polygon(28% 0, 100% 0, 100% 100%, 0% 100%);
} */
/* Dropdown Button */

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  /* background-color: #f1f1f1; */
  min-width: 160px;
  /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 5px 0;
  text-decoration: none;
  display: block;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.hover_Leauge:hover {
  background-color: #bdbdbd;
}
/* Faux outline for older browsers */
.outline {
  color: white; /* Unfortunately you can't use transparent here … */
  text-shadow:
   -1px -1px 0 #000,  
  1px -1px 0 #000,
  -1px 1px 0 #000,
   1px 1px 0 #000
}


/* The container <div> - needed to position the popup content */
.popup {
  /* position: relative; */
  display: inline-block;
}

/* popup Content (Hidden by Default) */

.popup-content {
  display: block;
  position: absolute;
  /* background-color: #f1f1f1; */
  /* min-width: 450px; */
  /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
  /* z-index: 1; */
}
/* map label css */
.marker-label {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  color: #333333;
  margin-bottom: 70px;
  font-size: 14px;
  font-weight: 500;
  padding: 8px;
  position: relative;
  bottom: 5px;
  text-align: center;
  white-space: nowrap;
}
/* Go To Goggle map css */
.go-to-google-maps-button{
  position: absolute;
  z-index: 999;
  left: 50%;
  top: 50%;
  background-color: #39FD13;
  right: 50%;
  transform: translateX(-50%);
  width: max-content;
  padding: 0 14px;
  font-size: 14px;



  /* position: sticky;
    z-index: 999;
    margin-left: 15px;
    cursor: pointer;
    background-color: aliceblue;
    padding: 2px;
    border-radius: 10%;
    transform: translateX(-50%);
    left: 50%; */
}
/* time picker css */
.rc-time-picker-clear {
  display: none;
}

/* Links inside the popup */
/* .popup-content a {
  color: black;
  padding: 5px 0;
  text-decoration: none;
  display: block;
} */

/* Show the popup menu on hover */
/* .popup:hover .popup-content {
  display: block;
} */

/* .hover_Leauge:hover {
  background-color: #bdbdbd;
} */
/* Faux outline for older browsers */
/* .outline {
  color: white; 
  text-shadow:-1px -1px 0 #000,  
  1px -1px 0 #000,
  -1px 1px 0 #000,
   1px 1px 0 #000
} */

/* Real outline for modern browsers */
@supports((text-stroke: 1px black) or (-webkit-text-stroke: 1px black)) {
  .outline {
      color: transparent;
      -webkit-text-stroke: 1px #39fd13;
  text-stroke: 1px #39fd13;
      text-shadow: none;
  }
}

/* not for demo */
* {
box-sizing: border-box;
}
/* div.popup-content {
  width: 70%!important;
} */

/* .popup-content {
  width: 70%!important;
} */
#round{
  width: 70%;
}
/* .rounded{
  width: 50%!important;
} */
/* .popup-content {
  overflow: hidden;
  padding: 5px;
  width: 70%!important;
  background: none!important;
  border: none!important;
} */
.react-calendar {
  width: 100%!important;
  max-width: 100%;
  margin-top: 0px!important;
  background: white;
  border: 0px!important;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}



.react-calendar__tile:disabled {
  background-color: rgb(255, 255, 255)!important;
}
/* .disableDate .react-calendar__tile:disabled {
  background-color: rgb(205, 205, 205)!important;
  color: #757575 !important;
} */

button, input, optgroup, select {
 
  
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}


.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from{
  font-size: 25px;
  font-family: "Poppins";
  font-weight: 700;

}
.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button{
  display: none;
}
.react-calendar__navigation__arrow.react-calendar__navigation__next2-button{
  display: none;
}
.react-calendar__navigation button {
  
  color: #000000;
  font-size: 39px;
  font-weight: 700;
  background: none;
}
abbr{
  text-decoration: none!important;

}
.react-calendar__tile.react-calendar__month-view__days__day{
  font-weight: 600!important;
  font-family: "Poppins";
  position: relative;
  overflow: initial !important;
}
.react-calendar__month-view__weekdays__weekday{
  margin-top: 9px;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 20px;
  color: #000000;
}
/* .react-calendar__navigation button {
  min-width: 102px!important;
  background: none;
} */
@media (min-width: 768px) {
  .react-calendar__navigation button {
    min-width: 102px!important;
    background: none;
  }
}

.react-time-input-picker-wrapper{
  z-index: 999!important;
}
.react-calendar__tile--now {
  background: #fffefe!important;
  color: rgb(0, 0, 0)!important;
}
.react-calendar__month-view__days__day--weekend {
  color: #000000!important;
}
.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day{
 
  text-decoration: underline;
  /* background-color: white!important;
  background: white!important; */
  /* text-decoration-color: red; */
  color: rgb(0, 0, 0);
}
.react-calendar__tile--active {
  background: white!important;
  color: white;
}

.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day.selected-date{
  /* background-color: #39fd13 !important;
  background: #39fd13 !important; */
}

.selected-date{
  position: relative !important;
  /* background-color: #39fd13 !important;
  background: #39fd13 !important; */
}

.selected-date::after{
  content: "✔";
  position: absolute;
  top: 27%;
  right: 18%;
  color: #000;
}

.react-calendar__tile--active.selected-date{
  /* background-color: #39fd13 !important;
  background: #39fd13 !important; */
}

.radio {
  accent-color: #39fd13;;
}

.back-circle{
  border-radius: 50%;
  height: 90%;
  position: absolute;
  aspect-ratio: 1;
  top: 50%;
  left: 50%;
  right: 50%;
  bottom: 50%;
  z-index: 0;
  transform: translate(-50%, -50%);
}

button:has(.back-circle) {
  /* Button styles when it is immediately preceded by a div element with the back-circle class */
  /* background-color: white; */
  color: white !important;
}


/* .MapFull ~ div{
  width: 100%;
} */






abbr{
  position: relative;
  z-index: 1;
}
.rc-time-picker-input{
  font-size: 17px!important;
}
/* body, html {
height: 100%;
width: 100%;
padding: 0;
margin: 0;
font-size: 2.5em;
font-family: Impact;
letter-spacing: 2px;
} */

.number::-webkit-inner-spin-button, 
.number::-webkit-outer-spin-button { 
    appearance: none; 
}

@media screen and (min-width: 776px) {
  
  /*clippath*/
  .clippath {
    clip-path: polygon(13% 0, 100% 0, 100% 100%, 0% 100%);
  }
  .new_clippath{
    clip-path: polygon(0 0, 89% 0, 100% 100%, 0% 100%);
  }
  }
  .calendar .selected-date {
    border-radius: 50%;
    background-color: #007bff;
    color: white;
  }
  .rc-time-picker-panel {
    position: fixed !important;
  }
  .rc-time-picker-panel-select li {
    text-transform: uppercase !important;
  }
  .rc-time-picker-input {
    padding: 18px 9px !important;

}
  .rc-time-picker-input{
    text-transform: uppercase !important;
  }

   .rc-time-picker-panel-inner{
    top:13px!important;
  }


  .tooltip {
    display: none;
    position: absolute;
    top: -29px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #000;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    width: max-content;
    z-index: 1;
  }
  button:has(.back-circle):hover .tooltip{
    display: block !important;
  }
  .rcw-widget-container{
    /* max-width: 25%!important; */
  }
  @media (max-width: 1043px) {
    .rcw-widget-container{
      display: none!important;
    }
     .react-calendar__month-view__weekdays__weekday{
  font-size: 15px!important;
 }
  }
  
  .rcw-conversation-container .rcw-header {
    background-color: #000000!important;
  
}
@media (max-width: 1043px) {
#rcw-conversation-container{
  display: none!important;
}
}


.rcw-close-launcher {
margin-left: 13px;
}
.rcw-client .rcw-message-text {
  background-color: #3b3b3b!important;
  color: white;
  
}
.rcw-launcher {
  position: absolute !important;
  right: 2px !important;
  top: 4px !important;
  box-shadow: 0 0px 0px 0px #000 !important ;
  /* background-color: #000000!important; */
 
}
.rcw-close-widget-container {
  bottom: 10% !important;
  right: 0 !important;

}
.rcw-launcher.rcw-hide-sm{
  background-color: transparent!important;
}
.rcw-launcher{
  background-color: #39FD13!important;

}
 .rcw-close-launcher{
  filter: invert(30%) sepia(100%) saturate(2000%) hue-rotate(78deg)!important;
}
.rcw-open-launcher{
  filter: brightness(27%)!important;

}
.rcw-conversation-container .rcw-title {
  font-size: 15px!important;
  margin: 0;
  padding: 3px 0!important;
  /* margin-bottom: 9px!important; */
}
.rcw-header span{
  font-size: 13px!important;

}
.rcw-client .rcw-message-text {
 
  max-width: 298px!important;

}
.rcw-picker-btn{
  display: none!important;
}
.rcw-send{
  margin-left: 30px!important;
  margin-bottom: 5px!important;
}
.rcw-conversation-container .rcw-header {
  padding: 10px 0 11px!important;
}

.rcw-message-text p {
  font-size: 11px;
}
.rcw-conversation-container {
  min-width: 345px!important;
}
.rcw-launcher {
  height: 45px!important;
  width: 45px!important;
}

.react-select-input.css-b62m3t-container{
  width: 100% !important;
  padding-left: 0px!important;
  

}
.css-3iigni-container{
  width: 100% !important;
}
.react-select-input .css-1jqq78o-placeholder , .css-qbdosj-Input{
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  /* padding-left: 1.5rem; */
  /* padding-right: 1.5rem; */
  margin: 0;
}
.react-select-input .css-13cymwt-control {
  border-radius: 0.5rem;
}
.react-select-input .css-t3ipsp-control{
  border-radius: 0.5rem;
  border-color: transparent !important;
  box-shadow:none !important;
}
.css-t3ipsp-control:hover{
  border-color: transparent !important;
  box-shadow:none !important;
  border-radius: 0.5rem;
  
}
.react-select-input .css-1fdsijx-ValueContainer{
  padding: 0;
}
.custom-header {
  background-color: #f2f2f2;
}
.custom-header button {
  color: blue;
}
.rcw-client .rcw-message-text {
  padding: 7px!important
}
.rcw-response .rcw-message-text {
  background-color: #39fd13;

}

.rcw-message.rcw-message-client:nth-child(1){
  margin-top: 21px!important;
}
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons that are used to open the tab content */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  border: 1px solid #39fd13;
  border-top: none;
}
.tree {
  padding-left: 20px;
}

.player {
  margin-bottom: 10px;
}
.css-1bioabq{
  line-height:50px;
}



/* tree view  */

.subplayer{
    position: absolute;
    left: 112px;
    top: 50%;
    bottom: 50%;
    width: max-content;
    height: fit-content;
    transform: translateY(-50%);
}

.webkitdisplay{
  display: -webkit-inline-box;
}

.border-after::after{
    content: "";
    width: 100%;
    height: 1px;
    top: 50%;
    right: -17%;
    position: absolute;
    background: white;
    z-index: 0;
}

.border-after:nth-child(odd)::before{
  content: "";
  width: 1px;
  height: calc(1.25rem + 100%);
  top: 50%;
  right: -18%;
  position: absolute;
  background: white;
  z-index: 0;
}

.has-ul .border-after::before, .has-ul .border-after::after{
  content:none
}
.first-div > div:first-child .border-after::before{
  content:none
}

.first-div > div:last-child .border-after::after{
  content:none
}

.first-div > div:last-child .border-after::before{
  content:none
}

.w-\[16rem\]{
  width: 16rem;
}
.w-\[32rem\]{
  width: 32rem;
}
.w-\[48rem\]{
  width: 48rem;
}
.w-\[64rem\]{
  width: 64rem;
}
.w-\[80rem\]{
  width: 80rem;
}
.w-\[96rem\]{
  width: 96rem;
}
.w-\[112rem\]{
  width: 112rem;
}
.w-\[128rem\]{
  width: 128rem;
}


.menu {
  /* display: flex; */
  /* flex-direction: column; */
  /* gap: 10px; */
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s, translate 0.3s;
  position: fixed;
  left: 0;
  background: black;
  height: 100%;
  overflow: auto;
  display: block;
  transform: translateX(-900px);
  /* top: 0; */
  /* width: 100%; */
}

.menu.open {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
}

.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  cursor: pointer;
}

.line {
  width: 100%;
  height: 3px;
  background-color: black;
  transition: transform 0.3s;
}

.hamburger.open .line:nth-child(1) {
  transform: rotate(45deg) translate(4px, 4px);
}

.hamburger.open .line:nth-child(2) {
  opacity: 0;
}

.hamburger.open .line:nth-child(3) {
  transform: rotate(-45deg) translate(6px, -6px);
}


.text-overnew{
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}

/* background for status void */
.avoid_class{
  position: relative;

}
.avoid_class::after {
  content: "";
 
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background: red;
  top: 50%;
  bottom: 50%;
  transform: translateY(-50%) ;
  
}
.css-13cymwt-control{
 min-height: 50px!important;
 padding-left: 9px!important;
  margin: 0;
}
.css-t3ipsp-control{
  min-height: 50px!important;
 padding-left: 9px!important;

   margin: 0;
 }
 .rc-time-picker-panel .rc-time-picker-panel-column-3 .rc-time-picker-panel-placement-bottomLeft {
  top:227px!important
 }
 /* Add a unique class or ID to your PhoneInput component for targeting */
#myPhoneInput {
  position: relative;
}

/* Style the input field */
#myPhoneInput input {
  autocomplete: "off";
  -webkit-autofill: "off";
}

/* safari css */
@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) {
    select{
      height: 52px;
    }
  }   
}



/* safari css */
@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) {
    select{
      height: 52px;
    }
  }   
}
